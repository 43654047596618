<template>
  <div class="MeusDados">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo titulo="Meus Dados" @OpenMenuLateral="menuOpen = true"></Topo>
      <b-row>
        <b-col>
          <div v-if="associado" class="container mt-3">
            <b-form @submit.stop.prevent="onSubmit" class="text-left">
              <div v-if="associado.nome">
                <label>Nome:</label>
                {{ associado.nome }}
              </div>
              <div v-if="associado.cpfcnpj">
                <label>CPF:</label>
                {{ associado.cpfcnpj }}
              </div>

              <hr />
              <!--b-form-group label="Nome" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.nome"
                  placeholder="Nome"
                  name="Nome"
                  v-validate="{ required: true }"
                  :state="validateState('Nome')"
                  :class="validateClass('Nome')"
                ></b-form-input>
              </b-form-group-->
              <b-form-group label="Email" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.email"
                  placeholder="Email"
                  name="Email"
                  :state="validateState('Email')"
                  :class="validateClass('Email')"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Telefone"
                id="input-group-2"
                label-for="input-2"
              >
                <the-mask
                  id="input-2"
                  class="form-control"
                  v-model="form.telefone"
                  name="Telefone"
                  placeholder="Telefone"
                  :masked="true"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  v-validate="{ required: true, min: 14 }"
                  :state="validateState('Telefone')"
                  :class="validateClass('Telefone')"
                ></the-mask>
              </b-form-group>

              <b-form-group
                label="Celular"
                id="input-group-2"
                label-for="input-2"
              >
                <the-mask
                  id="input-2"
                  class="form-control"
                  v-model="form.celular"
                  name="Celular"
                  placeholder="Celular"
                  :masked="true"
                  :mask="['(##) #####-####']"
                  v-validate="{ required: true, min: 15 }"
                  :state="validateState('Celular')"
                  :class="validateClass('Celular')"
                ></the-mask>
              </b-form-group>

              <b-form-group
                label="Data de Nascimento"
                label-for="input-2"
                v-if="associado.tipo == 'Pessoa Física'"
              >
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="form.dataNascimento"
                  v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
                  placeholder="Data de Nascimento"
                  mask="##/##/####"
                  id="dataNascimento"
                  name="Data de Nascimento"
                  :masked="true"
                  :state="validateState('Data de Nascimento')"
                  :class="validateClass('Data de Nascimento')"
                ></the-mask>
              </b-form-group>
              <!--b-form-group :label="placeHolderCpf" label-for="input-2">
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="form.cpfcnpj"
                  v-validate="{ required: true }"
                  :placeholder="placeHolderCpf"
                  :mask="maskCpf"
                  id="cpfcnpj"
                  :name="placeHolderCpf"
                  :masked="true"
                  :state="validateState(placeHolderCpf)"
                  :class="validateClass(placeHolderCpf)"
                ></the-mask>
              </b-form-group-->
              <b-form-group
                label="RG"
                label-for="input-2"
                v-if="associado.tipo == 'Pessoa Física'"
              >
                <!--the-mask
              type="text"
              class="form-control"
              v-model="form.rgIe"
              v-validate="{ required: true }"
              placeholder="RG"
              mask="##.###-###"
              id="rgIe"
              name="RG"
              :masked="true"
              :state="validateState('RG')"
              :class="validateClass('RG')"
                ></the-mask-->
                <b-form-input
                  type="number"
                  id="input-2"
                  v-model="form.rgIe"
                  placeholder="RG"
                  name="RG"
                  v-validate="{ required: true }"
                  :state="validateState('RG')"
                  :class="validateClass('RG')"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Inscrição Estadual"
                label-for="input-2"
                v-else
              >
                <b-form-input
                  id="input-2"
                  v-model="form.rgIe"
                  placeholder="Inscrição Estadual"
                  name="Inscrição Estadual"
                  v-validate="{ required: true }"
                  :state="validateState('Inscrição Estadual')"
                  :class="validateClass('Inscrição Estadual')"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="associado.tipo != 'Pessoa Física'"
                id="input-group-2"
                label="Representante Legal"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.representanteLegal"
                  placeholder="Nome Completo Represente Legal"
                  name="Nome Completo Represente Legal"
                  v-validate="{ required: true }"
                  :state="validateState('Nome Completo Represente Legal')"
                  :class="validateClass('Nome Completo Represente Legal')"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="CPF do Representante Legal"
                label-for="input-2"
                v-if="associado.tipo != 'Pessoa Física'"
              >
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="form.cpfRepresentante"
                  v-validate="{ required: true }"
                  placeholder="Cpf do Representante"
                  mask="###.###.###-##"
                  id="cpfRepresentante"
                  name="Cpf do Representante"
                  :masked="true"
                  :state="validateState('Cpf do Representante')"
                  :class="validateClass('Cpf do Representante')"
                ></the-mask>
              </b-form-group>

              <hr />
              <b-form-group label="Cep">
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="form.cep"
                  v-validate="{ required: true }"
                  placeholder="CEP"
                  mask="##.###-###"
                  id="Cep"
                  name="Cep"
                  :masked="true"
                  @keyup.native="keymonitor"
                  :state="validateState('Cep')"
                  :class="validateClass('Cep')"
                ></the-mask>
              </b-form-group>

              <b-form-group label="Endereço" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.endereco"
                  placeholder="Endereço"
                  name="Endereço"
                  v-validate="{ required: true }"
                  :state="validateState('Endereço')"
                  :class="validateClass('Endereço')"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Número" label-for="input-2">
                <b-form-input
                  type="number"
                  id="input-2"
                  v-model="form.numero"
                  placeholder="Número"
                  name="Número"
                  v-validate="{ required: true }"
                  :state="validateState('Número')"
                  :class="validateClass('Número')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Complemento" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.complemento"
                  placeholder="Complemento"
                  name="Complemento"
                  :state="validateState('Complemento')"
                  :class="validateClass('Complemento')"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Bairro" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.bairro"
                  placeholder="Bairro"
                  name="Bairro"
                  v-validate="{ required: true }"
                  :state="validateState('Bairro')"
                  :class="validateClass('Bairro')"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Cidade" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.cidade"
                  placeholder="Cidade"
                  name="Cidade"
                  v-validate="{ required: true }"
                  :state="validateState('Cidade')"
                  :class="validateClass('Cidade')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Estado" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.estado"
                  placeholder="Estado"
                  name="Estado"
                  v-validate="{ required: true }"
                  :state="validateState('Estado')"
                  :class="validateClass('Estado')"
                ></b-form-input>
              </b-form-group>
              <b-button
                pill
                ref="btnAvancar"
                size="lg"
                type="submit"
                variant="info"
                class="btn btn-block mb-5"
                :style="`background-color:${temaCor.botaoFormularios};`"
                >Salvar</b-button
              > </b-form
            ><b-button
              pill
              size="lg"
              type="submit"
              variant="danger"
              class="btn btn-block mb-5"
              @click="modalEncerramento = true"
              >Encerrar Conta</b-button
            >
            <div class="mt-3 mb-3 p-5"></div>
          </div>
        </b-col>
      </b-row>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <b-modal
      v-model="modalEncerramento"
      hide-footer
      centered
      title="Encerramento de Conta"
    >
      <div class="alert alert-warning">
        <h4 class="mb-4">Atenção!!</h4>
        Ao clicar no botão abaixo, sua conta no APLICATIVO será encerrada.
      </div>
      <b-button
        pill
        size="lg"
        type="submit"
        variant="danger"
        class="btn btn-block mb-5"
        @click="encerrarConta()"
        >Encerrar Conta no APP</b-button
      >
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './TopoInterno';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import pdf from 'vue-pdf';
import moment from 'moment';
import locale from './../locale';

import colors from './../services/colors';
export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      modalEncerramento: false,
      menuOpen: false,
      carregando: false,
      regulamento: null,
      currentPage: 1,
      pageCount: 0,
      placeHolderCpf: 'Cpf',
      maskCpf: '###.###.###-##',
      associado: null,
      temaCor: {},
      dados: {},
      form: {
        nome: null,
        cpfcnpj: null,
        dataNascimento: null,
        rgIe: null,
        representanteLegal: null,
        cpfRepresentante: null,
        cep: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        estado: null,
        celular: null,
      },
    };
  },
  components: { Topo, ConteudoDash, MenuRodape, MenuLateral, pdf },
  methods: {
    formataStringData(data) {
      var dia = data.split('/')[0];
      var mes = data.split('/')[1];
      var ano = data.split('/')[2];

      //return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
      return [ano, mes, dia];
      // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    onLoad() {
      panzoom(this.$refs.mypdf);
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    keymonitor: function (event) {
      if (this.form.cep.length == 10) {
        this.buscarCep();
      }
    },
    encerrarConta() {
      service
        .put(
          'Associado',
          'EncerrarContaApp/' + this.decoded.id_prospect,
          this.form,
          {},
        )
        .then((res) => {
          this.$bvToast.toast('Conta no App encerrada com sucesso', {
            title: 'Atenção!',
            solid: true,
            variant: 'success',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
          this.modalEncerramento = false;
          this.$router.push('/?idEmpresa=' + this.decoded.id_empresa);
        })
        .catch((e) => {
          this.$bvToast.toast(e, {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });
        });
    },
    onSubmit(evt) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        service
          .put(
            'Associado',
            'Atualizar/' + this.decoded.id_prospect,
            this.form,
            {},
          )
          .then((res) => {
            this.$bvToast.toast('Dados Atualizados com sucesso', {
              title: 'Atenção!',
              solid: true,
              variant: 'success',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          })
          .catch((e) => {
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    buscarCep() {
      if (this.form.cep.length == 10) {
        this.carregando = true;
        var cepbusca = this.form.cep.replace('.', '').replace('-', '');
        //console.log(dadosFrete);
        service
          .post('Cep', 'BuscaCEP', {
            cep: cepbusca,
          })
          .then((res) => {
            this.encontrouCep = true;
            this.exibeCidade = true;
            const parsed = JSON.stringify(res.data);
            this.form.endereco = res.data.street;
            this.form.bairro = res.data.neighborhood;
            this.form.cidade = res.data.city;
            this.form.estado = res.data.state;
            this.form.pais = 'BR';
            this.carregando = false;
          })
          .catch((e) => {
            this.encontrouCep = false;
            this.exibeCidade = false;
            this.carregando = false;
            this.form.endereco = null;
            this.form.bairro = null;
            this.form.cidade = null;
            this.form.estado = null;
            this.form.pais = null;
            this.$bvToast.toast('Não foi possível buscar o CEP', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      }
    },
    preencheForm() {
      this.form.nome = this.associado.nome;
      this.form.email = this.associado.email;
      this.form.telefone = this.associado.telefone;
      this.form.celular = this.associado.celular;
      this.form.cpfcnpj = this.associado.cpfcnpj;
      this.form.dataNascimento = moment(this.associado.dataNascimento)
        .utc()
        .format('DD/MM/YYYY');
      this.form.rgIe = this.associado.rgIe;
      this.form.representanteLegal = this.associado.representanteLegal;
      this.form.cpfRepresentante = this.associado.cpfRepresentante;
      this.form.cep = this.associado.cep;
      this.form.endereco = this.associado.endereco;
      this.form.numero = this.associado.numero;
      this.form.complemento = this.associado.complemento;
      this.form.bairro = this.associado.bairro;
      this.form.cidade = this.associado.cidade;
      this.form.estado = this.associado.estado;
    },
    verificaToken() {
      this.temaCor = colors.getColorsTema();
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);

        service
          .getAll('g', {}, 'empresas/list/idEmpresa/' + this.decoded.id_empresa)
          .then((res) => {
            this.dados = res.data[0];
          })
          .catch((e) => {
            this.carregando = false;
            this.erroConexao =
              'Seu aplicativo parece estar offline, verifique sua conexao';
          });

        service
          .getAll(
            'g',
            {},
            'associados/list/idProspect/' + this.decoded.id_prospect,
          )
          .then((res) => {
            this.associado = res.data[0];
            if (this.associado.tipo != 'Pessoa Física') {
              this.placeHolderCpf = 'Cnpj';
              this.maskCpf = '##.###.###/####-##';
            }
            this.preencheForm();
          })
          .catch((e) => {
            this.carregando = false;
            this.erroConexao =
              'Seu aplicativo parece estar offline, verifique sua conexao';
          });
      }
    },
  },
  mounted() {
    this.verificaToken();
    this.$validator.localize('en', locale);
  },
};
</script>

<style lang="scss" scoped>
.MeusDados {
  padding-bottom: 80px;
  label,
  label.d-block,
  .form-group {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    font-family: 'robotobold';
  }
}
</style>
